export const exc = ["90m Digital Elevation Satellite data (SRTM)", "Rivers of World", "Human Settlement Footprint", "Global Waterbodies and Change", "Daily Land Surface Temperature", "Temperature above (2m)ground", "Administrative Boundaries"]
 export const senBandsList = ["B1", "B2", "B3", "B4", "B5", "B6", "B7", "B8", "B8A", "B9", "B11", "B12"]
 export const landBandsList = { "B1": "SR_B1", "B2": "SR_B2", "B3": "SR_B3", "B4": "SR_B4", "B5": "SR_B5", "B6": "SR_B6", "B7": "SR_B7", "B10": "ST_B10", }
  export const landBandsListDis = ["B1", "B2", "B3", "B4", "B5", "B6", "B7", "B10", ]
  export const landBandsRev = {
    SR_B1: 'B1',
    SR_B2: 'B2', 
    SR_B3: 'B3',
    SR_B4: 'B4', 
    SR_B5: 'B5',
    SR_B6: 'B6',
    SR_B7: 'B7',
    ST_B10: 'B10',
  }
 
  export const sen2Add=["Ultra Blue","Blue","Green","Red","VNIR","VNIR","VNIR","VNIR","VNIR","SWIR","SWIR","SWIR","SWIR"]
  export const lan2Add=["Ultra Blue","Blue","Green","Red","Near-Infrared","SWIR 1","SWIR 2","TIRS 1",]
  export const colorsList = [
    'linear-gradient(to right, #5E5AF2, #EBF3E9, #00441b)',
    'linear-gradient(to right, #FFFFFF, #4147B4, #010AB7)',
    'linear-gradient(to right, #E00303, #FFFFFF, #2A33E0)',
    'linear-gradient(to right, #661C1C, #C9CA69, #073400)',
    'linear-gradient(to right, #84420E, #DDDECC, #001D80)',
    'linear-gradient(to right, #C30D0D, #EEEEAF, #3C8612)',
    'linear-gradient(to right, #0027A9, #EEEEAF, #D81414)',
  ];


  export const placedOrders = ["UP42", "SkyWatch"];