// const BASE_URL = "http://127.0.0.1:8000";
const BASE_URL = "https://portal.vasundharaa.in";

const HOST = `${BASE_URL}/maps`;
const HOST_MEDIA_URL = BASE_URL;
const HOST_HIGH_RES_DATA_PROVIDERS_URL = `${BASE_URL}/high-res-data`;
const HOST_CHATBOT_APP_URL = `${BASE_URL}/chatbot`;
const HELP_SUPPORT_URL = `${BASE_URL}/help-support`;
const PHONEPE_URL = `${BASE_URL}/phonepe-sdk`;

export {
  HOST,
  HOST_MEDIA_URL,
  HOST_HIGH_RES_DATA_PROVIDERS_URL,
  HOST_CHATBOT_APP_URL,
  HELP_SUPPORT_URL,
  PHONEPE_URL,
};
