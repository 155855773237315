import React, { useContext } from 'react';
import { useState } from 'react';
import { HOST } from "../host";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./otp.css"; // Import the CSS file where you define the styles
import { logToServer } from '../logger';
import OtpInput from 'react-otp-input';
import { GlobalContext } from '../../App';
function useForceUpdate() {
  const [, setValue] = useState(0);

  return () => setValue(value => value + 1);
}

function OTP({ setReset, setisVerified, isVerified }) {
  const [otp, setOTP] = useState("");
  const forceUpdate = useForceUpdate();
  const { getCsrfToken } = useContext(GlobalContext)
  const handleChange = (value) => {
    setOTP(value)
  };

  async function verify(e) {
    e.preventDefault();
    if (otp && String(otp).length === 4) {
      const key = sessionStorage.getItem('otpkey');
      const expirationTime = sessionStorage.getItem('otp_expiration');
      const currentTime = new Date().getTime();
      if (key && expirationTime) {
        if (currentTime > expirationTime) {
          sessionStorage.removeItem('otpkey');
          sessionStorage.removeItem('otp_expiration');
          toast.error("OTP has expired. Please try again");
          return
        }
      } else {
        toast.error("Unexpected error occured. Please resend OTP");
        return
      }
      const otpString = String(otp);
      try {
        const res = await fetch(`${HOST}/verify-otp/${otpString}/${key}`, {
          method: "POST",
          credentials: 'include',
          body: {},
          headers: {
            'X-CSRFToken': await getCsrfToken(),
          },
        });

        if (res.status === 400) {
          toast.error("OTP could not be verified");
          logToServer('error', 'OTP could not be verified')
        } else if (res.status === 200) {
          toast.success(`OTP Verified`);
          setisVerified(true);
          logToServer('info', 'OTP Verified successfully')
          sessionStorage.removeItem('otpkey');
          sessionStorage.removeItem('otp_expiration');
          // setReset(true);
        }
      }
      catch (e) {
        toast.error("Invalid credentials");
        console.log('error',e);
        logToServer('error', `${e}`)
      }
    } else {
      toast.error("Please type OTP or type all 4 digits");
    }
  }

  return (
    <>
      <div className="container-fluid" style={{ padding: '12px 12px 5px 12px' }}>
        <div className="row p-12 rounded-3 shadow-sm">
          <div className="row justify-content-center otp-input">
            {/* <input className="otp-input" required type="text" autoComplete="one-time-code" inputMode="numeric" maxLength="4" onChange={(e) => handleChange(e.target.value)} value={otp}></input> */}
            <OtpInput
              value={otp}
              onChange={(value) => handleChange(value)}
              numInputs={4}
              renderInput={(props) => <input {...props} className='otp-container' style={{ width: '4em', padding: '15px 0px 15px 25px' }} />}
            />
          </div>
          <button onClick={(e) => (verify(e))} className="mt-3 button" style={{ border: '2px solid #007bff', backgroundColor: 'transparent', color: 'white' }}
            id="back-btn">Verify</button>
        </div>
      </div>



    </>
  );
}

export default OTP;
